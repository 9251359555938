<template>
  <div id="about">

    <h1>Abouty</h1>

    <p>
      The word <em>aboudé</em> is French, and means <em>on the subject of; concerning</em>.
    </p>

    <section>
      <!--<h2>web services</h2>-->
      <p class="copy">
        Catomatic is a one-person development team specializing in tiny web projects.
        We can get your phone number out to potential customers
        and manage your database too, all within your budget
        <!--Catomatic has service provider preferences.-->
      </p>
    </section>

    <section>
      <h3>delivering custom interfaces</h3>
      <p class='copy'>
        It's code that your clients touch
        and comes alive when connected to you.
        From interactive messaging to retail carts & menus, 
        it's all about that audience engagement paradigm
        <!--These are the web equivalents of
        a business card or pamphlet.
        or a retail environment (storefront, drive-thru menu)-->
      </p>
    </section>

    <section>
      <h3>hosting backend processes</h3>
      <p class='copy'>
        Servers are robots who live in clouds,
        accepting requests and answering questions.
        <!--They eat electricity and updates but have never disobeyed.-->
        Let's get these guys to handle your business
      </p>
    </section>

    <section>
      <h3>implementing brand strategies</h3>
      <p class='copy'>
        If you don't have one, let's make one up.  We could also talk about
        user acquisition flow, market survey history, and psychographic pie charts
        <!--Do you have a visual style guide?
        Catomatic can run with it.
        If you need it, we can -->
      </p>
    </section>

  </div>
</template>

<style lang="scss">
#about {
  section {
    text-align: left;

    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 20%;
    margin-right: 20%;

    border: 3px dashed pink;
    border: none;
  }
}
</style>

<script>
import { eventService } from "@/service/event"

export default {

  data() {
    return {
      debug: 1
    }
  },

  mounted() {
    eventService.log(['load','about'])
  }

}
</script>
